import { NavLink } from 'react-router-dom';
import { useProject } from '../../../tools/ProviderContext';

import './Nav.css';
import './Nav.css';

function Nav() {
  const { closeBurgerMenu, isBurgerMenu } = useProject();
  return (
    <>
      <nav className={isBurgerMenu ? 'open menu' : 'menu'}>
        <ul className={'menu__list'}>
          <li className={'menu__item'}>
            <NavLink to="/" className="menu__link" onClick={closeBurgerMenu}>
              Główny
            </NavLink>
          </li>
          <li className={'menu__item'}>
            <NavLink
              to="/about"
              onClick={closeBurgerMenu}
              className="menu__link"
            >
              O nas
            </NavLink>
          </li>
          <li className={'menu__item'}>
            <NavLink
              to="/price"
              className="menu__link"
              onClick={closeBurgerMenu}
            >
              Usługi
            </NavLink>
          </li>
          <li className={'menu__item'}>
            <NavLink
              to="/article1"
              className="menu__link"
              onClick={closeBurgerMenu}
            >
              Blog
            </NavLink>
          </li>
          <li className={'menu__item'}>
            <NavLink
              to="/contact"
              className="menu__link"
              onClick={closeBurgerMenu}
            >
              Kontakt
            </NavLink>
          </li>
        </ul>
      </nav>
    </>
  );
}
export default Nav;
