import { useState, useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Button, ButtonLang } from '../../../UI/Buttons/Buttons';
import Lang from '../../../UI/Lang/Lang';
import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import './Articles.css';
import quilon from '../../../img/articles/article1.jpg';
import quilon2 from '../../../img/articles/Article1.2.png';
import WhatsApp from '../../../WhatsUp/WhatsappLink';

function ButtomsLang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };
  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div>
        <Lang lang="lang" marginTop="20px">
          <Button onClick={changeLang} select="select">
            <span>PL</span>
            <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />
          </Button>
          <ButtonLang isLang={isLang}>
            {isLang && <Link to="/en/article3">EN</Link>}
          </ButtonLang>
        </Lang>
      </div>
    </>
  );
}
export function Cataloge() {
  const [isScroll, setScroll] = useState(true);
  if (isScroll) {
    window.scroll({ top: 0, behavior: 'smooth' });
    setScroll(false);
  }
  useEffect(() => {
    if (isScroll) {
      window.scroll({ top: 1000, behavior: 'smooth' });
      setScroll(false);
    }
  }, [isScroll]);

  const toggle = () => {
    setScroll(true);
  };

  return (
    <div className="catalogs">
      <h3>Blog</h3>
      <ul>
        <li onClick={toggle}>
          <NavLink to="/article1">
            <span>Dlaczego diagnostyka samochodu jest ważna?</span>
          </NavLink>
        </li>
        <li onClick={toggle}>
          <NavLink to="/article2">
            <span>
              Obsługa techniczna i naprawa zawieszenia: porady dla
              początkujących
            </span>
          </NavLink>
        </li>
        <li onClick={toggle}>
          <NavLink to="/article3">
            Główne problemy podwozia samochodu: diagnostyka i metody naprawy
          </NavLink>
        </li>
      </ul>
    </div>
  );
}

function Quilon() {
  return (
    <>
      <div className="containerarticel">
        <div className="atricelandcatalog">
          <div className="articelquilon">
            <h2>Dlaczego diagnostyka samochodu jest ważna?</h2>
            <p>
              Diagnostyka samochodowa to proces sprawdzania stanu różnych
              systemów i komponentów pojazdu w celu wykrycia potencjalnych
              usterek lub problemów. Pozwala właścicielom samochodów i
              mechanikom na terminowe wykrywanie i usuwanie problemów, co pomaga
              uniknąć poważnych awarii, zwiększa bezpieczeństwo jazdy i
              przedłuża żywotność pojazdu. Diagnostyka komputerowa jest jednym z
              najnowocześniejszych metod diagnostyki, wykorzystującym
              specjalistyczny sprzęt i oprogramowanie.
            </p>

            <img className="quilon" src={quilon} title="quilon" alt="quilon" />
            <p>
              Po pierwsze, diagnostyka pozwala określić stan silnika samochodu.
              Za pomocą specjalnych czujników i skanerów system komputerowy
              odczytuje dane dotyczące pracy silnika, w tym takie parametry jak
              obroty, temperatura, ciśnienie oleju, skład spalin itp. To pomaga
              wykryć potencjalne problemy z systemem wtrysku paliwa, zapłonu,
              systemem chłodzenia i innymi komponentami silnika.
            </p>
            <p>
              Po drugie, diagnostyka obejmuje sprawdzenie elektronicznych
              systemów pojazdu. Nowoczesne samochody są wyposażone w liczne
              moduły elektroniczne, które kontrolują różne aspekty pracy
              pojazdu, od systemu hamulców po system stabilizacji. Diagnostyka
              komputerowa pozwala sprawdzić stan tych systemów, odczytać kody
              błędów i wykryć usterki, które mogą wpływać na bezpieczeństwo i
              komfort jazdy.
            </p>
            <img
              className="quilon2"
              src={quilon2}
              title="quilon"
              alt="quilon"
            />

            <p>
              Trzecim aspektem diagnostyki jest sprawdzenie skrzyni biegów.
              Skrzynia biegów przekazuje moc z silnika na koła i zapewnia
              właściwą zmianę biegów. Problemy ze skrzynią biegów mogą prowadzić
              do znacznych kosztów naprawy, dlatego ważne jest terminowe
              wykrywanie i usuwanie wszelkich usterek. Diagnostyka komputerowa
              pomaga określić stan skrzyni biegów i wykryć problemy z
              automatyczną lub manualną skrzynią biegów.
            </p>
            <p>
              Kolejny etap diagnostyki obejmuje sprawdzenie systemu hamulcowego.
              Hamulce są jednym z najważniejszych systemów bezpieczeństwa
              pojazdu, dlatego ich stan powinien być zawsze w idealnym porządku.
              Diagnostyka hamulców pozwala sprawdzić stan klocków hamulcowych,
              tarcz, płynu hamulcowego oraz działanie ABS i innych systemów
              wspomagających hamowanie.
            </p>
            <p>
              Ponadto diagnostyka obejmuje sprawdzenie zawieszenia i układu
              kierowniczego. System zawieszenia odpowiada za komfort i
              sterowność pojazdu, a układ kierowniczy - za precyzję i
              bezpieczeństwo prowadzenia. Wykrywanie usterek w tych systemach
              pomaga zapewnić bezpieczną i komfortową jazdę oraz uniknąć
              dodatkowych kosztów naprawy.
            </p>
            <p>
              Ostatni aspekt diagnostyki dotyczy systemu klimatyzacji i kontroli
              klimatu. Systemy te zapewniają komfort wewnątrz pojazdu,
              szczególnie w ekstremalnych warunkach pogodowych. Diagnostyka
              klimatyzacji obejmuje sprawdzenie stanu sprężarki, poziomu
              czynnika chłodzącego, filtrów i innych komponentów systemu.
            </p>
          </div>
          <Cataloge />
        </div>
      </div>
    </>
  );
}

function Article1() {
  return (
    <div className="body_wrapper">
      <Header />
      {/* <ButtomsLang /> */}
      <Quilon />
      <WhatsApp />
      <Footer />
    </div>
  );
}
export default Article1;
