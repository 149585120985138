import MyEmail from '../../../UI/MyEmail/MyEmail';
import MyPhone from '../../../UI/MyPhone/MyPhone';
import MySocialLinks from '../../../UI/MySocialLinks/MySocialLinks';
import BurgerBtnMenu from '../BurgerBtnMenu/BurgerBtnMenu';
import Logo from '../Logo/Logo';
import Nav from '../Nav/Nav';
import Zayavka from '../Zayavka/Zayavka';
import Location from '../Location/Location';
import Text from '../Header_text/Text';
import './Header.css';
import { useState } from 'react';
function Header() {
  const [isHeader, setHeader] = useState(false);
  window.addEventListener('scroll', function () {
    const scrolled = window.scrollY;
    if (scrolled > 100) {
      setHeader(true);
    } else if (scrolled < 100) {
      setHeader(false);
    }
  });
  return (
    <>
      <header className={'header'}>
        <div className={isHeader ? 'header__fon-scroll' : 'header__fon'}>
          <div className="container header__container">
            <div
              className={
                isHeader ? 'header__contacts-scroll' : 'header__contacts'
              }
            >
              <div className="header__location">
                <Location />
              </div>
              <div className="social-email-phone">
                <div className="social-email">
                  <MySocialLinks />
                  <MyEmail />
                </div>
                <MyPhone />
              </div>
            </div>
            <div className="header__menu">
              <Logo />
              <Nav />
              <div className="header__menu-zyavka-burger">
                <Zayavka />
                <BurgerBtnMenu />
              </div>
            </div>
            <Text />
          </div>
        </div>
      </header>
    </>
  );
}
export default Header;
