import { Link } from 'react-router-dom';
import './MyPhone.css';

function MyPhone(props) {
  const { className = 'header_phone_h3', classnamelink = 'header_phone_link' } =
    props;

  return (
    <Link {...props} to={'tel:+48662401213'} className={classnamelink}>
      <h3 {...props} className={className} title="phone">
        +48 662401213
      </h3>
    </Link>
  );
}
export default MyPhone;
