import React from 'react';
import { IoLocationOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import './Location.css';

function Location() {
  return (
    <Link
      to={'https://maps.app.goo.gl/PEqX4jMzr8C2A3n69'}
      className="location"
      target="_blank"
      alt="location"
    >
      <IoLocationOutline className="location_img" />
      <span>Gniezno, Wodna 4 </span>
    </Link>
  );
}

export default Location;
