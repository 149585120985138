import React from 'react';
import foto1 from '../../../img/header/lift.png';
import foto2 from '../../../img/header/car-repair.png';
import foto3 from '../../../img/header/repair-shop.png';
import './Text.css';

function Text() {
  return (
    <div className="text">
      <h1 className="text_h1">
        <span className="text_span">Oferujemy:</span> <br />
        <span className="text_span2">
          <img src={foto1} alt="diagnostics" className="diagnostics" />{' '}
          Diagnostyka samochodów
        </span>
        <span className="text_span3">
          {' '}
          <img src={foto2} alt="diagnostics" className="diagnostics" />{' '}
          konserwację samochodów
        </span>
        <span className="text_span4">
          {' '}
          <img src={foto3} alt="diagnostics" className="diagnostics" /> wysokiej
          jakości naprawy samochodów
        </span>
      </h1>
    </div>
  );
}
export default Text;
