import { useState } from 'react';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import './Price.css';
import { Button, ButtonLang } from '../../../UI/Buttons/Buttons';
import Lang from '../../../UI/Lang/Lang';
import WhatsApp from '../../../WhatsUp/WhatsappLink';
import { ServicesOnly } from '../../2_Main/Services/Services';
import foto from '../../../img/price/foto.jpg';
import { FormaZayavkaContact } from '../../Forma/Forma_zayavka';
import MyPhone from '../../../UI/MyPhone/MyPhone';

function ButtomsLang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };
  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div>
        <Lang lang="lang" marginTop="20px">
          <Button onClick={changeLang} select="select">
            <span>PL</span>
            <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />
          </Button>
          <ButtonLang isLang={isLang}>
            {isLang ? (
              <Link to="/en/price">EN</Link>
            ) : (
              <Link to="/price">RU</Link>
            )}
          </ButtonLang>
        </Lang>
      </div>
    </>
  );
}

export function PriceTable() {
  const scrolldown = () => {
    const scrollY = document.querySelector('body').clientHeight;

    window.scroll({ top: scrollY - 1000, behavior: 'smooth' });
  };
  return (
    <>
      <div className="price">
        <div className="price-title">
          <h1>Usługi</h1>
        </div>

        <div className="price__services">
          <ServicesOnly />
        </div>
        <div className="changeoil__img-text">
          <img src={foto} alt="changeoil" className="changeoil-img" />
          <div className="changeoil-text">
            <p>
              Obliczanie kosztów naprawy samochodu może być trudnym zadaniem,
              ponieważ zależy od wielu czynników.
            </p>
            <p>
              Cena naprawy samochodu zależy od modelu samochodu, kosztu
              materiałów eksploatacyjnych i charakter szkody.
            </p>
            <p>
              Dokładny koszt pracy możesz poznać dzwoniąc na nasz numer telefonu{' '}
            </p>
            <MyPhone className="price-phone" />
            <span>
              lub zostawiając zapytanie. Możesz także odwiedzić nasze centrum
              serwisowe.
            </span>

            <div className="changeoil-zayavka">
              <button
                onClick={scrolldown}
                type="button"
                className="changeoil-link"
              >
                Zarejestruj się w serwisie
              </button>
            </div>
          </div>
        </div>
        <div className="formazayavka">
          <FormaZayavkaContact />
        </div>
      </div>
    </>
  );
}

function Price() {
  return (
    <div className="body_wrapper">
      <Header />

      {/* <ButtomsLang /> */}
      <PriceTable />
      <WhatsApp />
      <Footer />
    </div>
  );
}
export default Price;
