import './Hamulcowe.css';
import Header from '../../1_Header/Container/Header';
import changeoil from '../../../img/tormoz/brake-systems-img.jpg';
import { FormaZayavkaContact } from '../../Forma/Forma_zayavka';
import Footer from '../../3_Footer/Footer';
import WhatsApp from '../../../WhatsUp/WhatsappLink';

function Hamulcowe() {
  const scrolldown = () => {
    const scrollY = document.querySelector('body').clientHeight;

    window.scroll({ top: scrollY - 1000, behavior: 'smooth' });
  };
  return (
    <>
      <div className="body_wrapper">
        <Header />
        <div className="changeoil">
          <h1>Wymiana uklady hamulcowe</h1>
          <div className="changeoil__img-text">
            <img src={changeoil} alt="changeoil" className="changeoil-img" />
            <div className="changeoil-text">
              <p>
                Trudno przecenić znaczenie prawidłowego działania układu
                hamulcowego samochodu. Naprawa lub wymiana podzespołów wymaga
                użycia wysokiej jakości części zamiennych oraz pracy prawdziwych
                fachowców, tak aby nie stworzyć zagrożenia dla siebie i innych
                uczestników ruchu drogowego.
              </p>
              <p>Serwis układu hamulcowego obejmuje:</p>
              <ul className="hamulcowe">
                <li>Diagnostyka elementów systemu i sprawdzanie usterek</li>
                <li>
                  Regulacja hamulca ręcznego i naprawa zacisku hamulcowego
                </li>
                <li>
                  Demontaż zużytych części (najczęściej wymiana cylindra
                  hamulcowego, bębna i klocków hamulcowych, tarcz hamulcowych i
                  płynów)
                </li>
                <li>Dobór i montaż oryginalnych części zamiennych</li>
                <li>Kontrola wydajności systemu</li>
              </ul>
              <div className="changeoil-zayavka">
                <button
                  onClick={scrolldown}
                  type="button"
                  className="changeoil-link"
                >
                  Zarejestruj się w serwisie
                </button>
              </div>
            </div>
          </div>

          <div className="formazayavka">
            <FormaZayavkaContact />
          </div>
        </div>
        <Footer />
        <WhatsApp />
      </div>
    </>
  );
}

export default Hamulcowe;
