import Header from '../../1_Header/Container/Header';
import changeoil from '../../../img/Zcheplenya/remont-sczepleniya-avto.jpg';
import { FormaZayavkaContact } from '../../Forma/Forma_zayavka';
import Footer from '../../3_Footer/Footer';
import WhatsApp from '../../../WhatsUp/WhatsappLink';

function Sprzegiel() {
  const scrolldown = () => {
    const scrollY = document.querySelector('body').clientHeight;

    window.scroll({ top: scrollY - 1000, behavior: 'smooth' });
  };
  return (
    <>
      <div className="body_wrapper">
        <Header />
        <div className="changeoil">
          <h1>Wymiana sprzęgiel</h1>
          <div className="changeoil__img-text">
            <img src={changeoil} alt="changeoil" className="changeoil-img" />
            <div className="changeoil-text">
              <p>
                Układ łączący i rozłączający koło zamachowe silnika i skrzynię
                biegów nazywany jest sprzęgłem. Żaden samochód nie może obejść
                się bez tak ważnej jednostki, ale czasami się psuje.
              </p>
              <p>
                Niektóre sytuacje wymagają naprawy sprzęgła, inne zaś wymagają
                zupełnie nowego układu. Tylko profesjonalny mechanik dysponujący
                specjalnym sprzętem i programami diagnostycznymi będzie w stanie
                określić stopień zużycia.
              </p>
              <p>
                Regularnie poddawaj się przeglądom technicznym, aby w porę
                wykryć problem i uniknąć poważnych problemów.
              </p>
              <div className="changeoil-zayavka">
                <button
                  onClick={scrolldown}
                  type="button"
                  className="changeoil-link"
                >
                  Zarejestruj się w serwisie
                </button>
              </div>
            </div>
          </div>

          <div className="formazayavka">
            <FormaZayavkaContact />
          </div>
        </div>
        <Footer />
        <WhatsApp />
      </div>
    </>
  );
}

export default Sprzegiel;
