import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import foto1 from '../../img/articles/article1.jpg';
import foto2 from '../../img/articles/article2.jpg';
import foto3 from '../../img/articles/article3.jpg';
import './General_img.css';

function Block() {
  return (
    <div className="block">
      <h3>Dlaczego diagnostyka samochodu jest ważna?</h3>
    </div>
  );
}
function BlockOneImgP() {
  return (
    <div>
      <img src={foto1} alt="foto1" className="fotoImg" />
      <div className="special">
        <p>
          Diagnostyka samochodowa to proces sprawdzania stanu różnych systemów i
          komponentów pojazdu w celu wykrycia potencjalnych usterek lub
          problemów. Pozwala właścicielom samochodów i mechanikom na terminowe
          wykrywanie i usuwanie problemów, co pomaga uniknąć poważnych awarii,
          zwiększa bezpieczeństwo jazdy i przedłuża żywotność pojazdu.
          Diagnostyka komputerowa jest jednym z najnowocześniejszych metod
          diagnostyki, wykorzystującym specjalistyczny sprzęt i
          oprogramowanie...
        </p>
      </div>
    </div>
  );
}
function BlockOneCheck() {
  return (
    <div className="check">
      <Link to="/article1">Szczegółowo...</Link>
    </div>
  );
}
function BlockOneFromArticle1() {
  return (
    <div className="block_1">
      <Block />
      <BlockOneImgP />
      <BlockOneCheck />
    </div>
  );
}
function BlockTwo() {
  return (
    <div className="block">
      <h3>
        Obsługa techniczna i naprawa zawieszenia: porady dla początkujących
      </h3>
    </div>
  );
}
function BlockTwoImgP() {
  return (
    <div>
      <img src={foto2} alt="foto9" className="fotoImg" />
      <p className="special">
        Zawieszenie samochodu jest jednym z najważniejszych systemów
        zapewniających komfort i bezpieczeństwo podczas jazdy. Składa się z
        różnych elementów, w tym amortyzatorów, sprężyn, wahaczy i
        stabilizatorów. Przestrzegając prostych porad i zaleceń, nawet
        początkujący mogą utrzymać zawieszenie w doskonałym stanie...
      </p>
    </div>
  );
}
function BlockTwoCheck() {
  return (
    <div className="check">
      <Link to="/article2">Szczegółowo...</Link>
    </div>
  );
}
function BlockTwoFromArticle2() {
  return (
    <div className="block_2">
      <BlockTwo />
      <BlockTwoImgP />
      <BlockTwoCheck />
    </div>
  );
}
function Block3() {
  return (
    <div className="block">
      <h3>Główne problemy podwozia samochodu: diagnostyka i metody naprawy</h3>
    </div>
  );
}
function BlockThreeImgP() {
  return (
    <div>
      <img src={foto3} alt="foto1" className="fotoImg" />
      <p className="special">
        Podwozie samochodu jest podstawową częścią konstrukcji, która zapewnia
        sztywność i stabilność pojazdu. Mimo swojej wytrzymałości, podwozie
        również podlega zużyciu i uszkodzeniom, co może wpływać na
        bezpieczeństwo i komfort jazdy. W tym artykule omówimy główne problemy
        podwozia, metody ich diagnostyki oraz możliwe sposoby naprawy...
      </p>
    </div>
  );
}
function BlockThreeCheck() {
  return (
    <div className="check">
      <Link to="/article3">Szczegółowo...</Link>
    </div>
  );
}
function BlockOneFromArticle3() {
  return (
    <div className="block_3">
      <Block3 />
      <BlockThreeImgP />
      <BlockThreeCheck />
    </div>
  );
}
function SwiperArticles() {
  return (
    <Swiper
      navigation
      effect={'coverflow'}
      grabCursor={true}
      centeredSlides={true}
      slidesPerView={'auto'}
      coverflowEffect={{
        rotate: 50,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: true,
      }}
      // pagination={{ type: 'fraction' }}
      modules={[EffectCoverflow, Navigation]}
      className="mySwiper"
    >
      <SwiperSlide>
        <BlockOneFromArticle1 />
      </SwiperSlide>
      <SwiperSlide>
        <BlockTwoFromArticle2 />
      </SwiperSlide>
      <SwiperSlide>
        <BlockOneFromArticle3 />
      </SwiperSlide>

      {/* <SwiperSlide></SwiperSlide> */}
    </Swiper>
  );
}
export default SwiperArticles;
