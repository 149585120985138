import { useProject } from '../../../tools/ProviderContext';
import './Btn.css';

function BurgerBtnMenu() {
  const { openMenu, open, isBurgerMenu } = useProject();
  return (
    <>
      <button
        onClick={() => {
          open();
          openMenu();
        }}
        className={
          isBurgerMenu ? 'open header__burger-btn' : 'header__burger-btn'
        }
      >
        <span className="header__burger-item"></span>
        <span className="header__burger-item"></span>
        <span className="header__burger-item"></span>
      </button>
    </>
  );
}
export default BurgerBtnMenu;
