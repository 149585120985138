import { useState } from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Button, ButtonLang } from '../../../UI/Buttons/Buttons';
import Lang from '../../../UI/Lang/Lang';
import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import './Articles.css';
import quilon from '../../../img/articles/article2.jpg';
import quilon2 from '../../../img/articles/article2.2.jpg';
import { Cataloge } from './1_Article';
import WhatsApp from '../../../WhatsUp/WhatsappLink';

function ButtomsLang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };
  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div>
        <Lang lang="lang" marginTop="20px">
          <Button onClick={changeLang} select="select">
            <span>PL</span>
            <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />
          </Button>
          <ButtonLang isLang={isLang}>
            {isLang && <Link to="/en/article1">EN</Link>}
          </ButtonLang>
        </Lang>
      </div>
    </>
  );
}

function Quilon() {
  return (
    <>
      <div className="containerarticel">
        <div className="atricelandcatalog">
          <div className="articelquilon">
            <h2>
              {' '}
              Obsługa techniczna i naprawa zawieszenia: porady dla
              początkujących
            </h2>
            <p>
              Zawieszenie samochodu jest jednym z najważniejszych systemów
              zapewniających komfort i bezpieczeństwo podczas jazdy. Składa się
              z różnych elementów, w tym amortyzatorów, sprężyn, wahaczy i
              stabilizatorów. Przestrzegając prostych porad i zaleceń, nawet
              początkujący mogą utrzymać zawieszenie w doskonałym stanie.
            </p>
            <img className="quilon" src={quilon} title="quilon" alt="quilon" />
            <p>
              Pierwszym krokiem w technicznej obsłudze zawieszenia jest
              regularna kontrola stanu amortyzatorów. Amortyzatory odgrywają
              kluczową rolę w zapewnieniu stabilności i komfortu jazdy, dlatego
              ważne jest regularne sprawdzanie ich pod kątem wycieków oleju i
              zużycia. Jeśli amortyzatory są uszkodzone lub zużyte, należy je
              wymienić, aby uniknąć dalszych problemów z zawieszeniem.
            </p>
            <p>
              Drugim ważnym aspektem jest kontrola stanu sprężyn zawieszenia.
              Sprężyny wspierają wagę samochodu i pomagają zapewnić płynność
              jazdy. Jeśli zauważysz, że samochód przechyla się na jedną stronę
              lub zawieszenie stało się twardsze, może to świadczyć o problemach
              ze sprężynami. Wymiana sprężyn wymaga pewnych umiejętności, więc
              jeśli nie jesteś pewien swoich sił, lepiej skonsultować się ze
              specjalistą.
            </p>
            <p>
              Regularna obsługa zawieszenia obejmuje również kontrolę stanu
              wahaczy i stabilizatorów. Wahacze zawieszenia łączą koła z ramą
              samochodu i pozwalają na ruch kół w górę i w dół. Stabilizatory
              pomagają zmniejszyć przechył samochodu podczas zakrętów. Zużyte
              wahacze lub stabilizatory mogą prowadzić do utraty kontroli i
              zwiększenia ryzyka wypadku. Dlatego ważne jest regularne
              sprawdzanie tych elementów i w razie potrzeby ich wymiana.
            </p>
            <img
              className="quilon2"
              src={quilon2}
              title="quilon"
              alt="quilon"
            />
            <p>
              Profilaktyczna obsługa zawieszenia obejmuje również regularną
              kontrolę i wymianę łożysk kół. Łożyska zapewniają płynny ruch kół
              i zmniejszają tarcie. Zużyte łożyska mogą powodować hałas,
              wibracje i inne problemy podczas jazdy. Regularna wymiana łożysk
              pomoże uniknąć poważnych problemów z zawieszeniem i przedłużyć
              jego żywotność.
            </p>
            <p>
              Warto również zwrócić uwagę na stan gumy i tulei gumowych.
              Elementy gumowe zawieszenia z czasem mogą tracić elastyczność i
              pękać. Może to prowadzić do zmniejszenia efektywności zawieszenia
              i pojawienia się nieprzyjemnych dźwięków podczas jazdy. Regularna
              wymiana zużytych tulei gumowych pomoże utrzymać zawieszenie w
              dobrym stanie i zapewnić komfortową jazdę.
            </p>
            <p>
              Na koniec, ważne jest, aby pamiętać o prawidłowej geometrii kół.
              Nieprawidłowe ustawienie kątów nachylenia i wyrównanie kół może
              prowadzić do nierównomiernego zużycia opon i zmniejszenia kontroli
              nad samochodem. Regularna kontrola i ustawianie geometrii kół
              pomoże uniknąć tych problemów i zapewnić trwałość zawieszenia.
            </p>
          </div>
          <Cataloge />
        </div>
      </div>
    </>
  );
}

function Article2() {
  return (
    <div className="body_wrapper">
      <Header />

      {/* <ButtomsLang /> */}
      <WhatsApp />
      <Quilon />
      <Footer />
    </div>
  );
}
export default Article2;
