import { useState } from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Button, ButtonLang } from '../../../UI/Buttons/Buttons';
import Lang from '../../../UI/Lang/Lang';
import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import './Articles.css';
import quilon from '../../../img/articles/article3.jpg';
import quilon2 from '../../../img/articles/article3.3.jpg';
import { Cataloge } from './1_Article';
import WhatsApp from '../../../WhatsUp/WhatsappLink';

function ButtomsLang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };
  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div>
        <Lang lang="lang" marginTop="20px">
          <Button onClick={changeLang} select="select">
            <span>PL</span>
            <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />
          </Button>
          <ButtonLang isLang={isLang}>
            {isLang && <Link to="/en/article2">EN</Link>}
          </ButtonLang>
        </Lang>
      </div>
    </>
  );
}

function Quilon() {
  return (
    <>
      <div className="containerarticel">
        <div className="atricelandcatalog">
          <div className="articelquilon">
            <h2>
              {' '}
              Główne problemy podwozia samochodu: diagnostyka i metody naprawy
            </h2>
            <p>
              Podwozie samochodu jest podstawową częścią konstrukcji, która
              zapewnia sztywność i stabilność pojazdu. Mimo swojej
              wytrzymałości, podwozie również podlega zużyciu i uszkodzeniom, co
              może wpływać na bezpieczeństwo i komfort jazdy. W tym artykule
              omówimy główne problemy podwozia, metody ich diagnostyki oraz
              możliwe sposoby naprawy.
            </p>
            <img className="quilon" src={quilon} title="quilon" alt="quilon" />
            <p>
              Pierwszym i najczęstszym problemem podwozia jest korozja.
              Szczególnie w regionach o wysokiej wilgotności lub gdzie stosuje
              się środki do walki z oblodzeniem dróg, metalowa konstrukcja
              podwozia może ulegać korozji. Wizualna inspekcja jest najprostszą
              metodą diagnostyki korozji. Aby z nią walczyć, należy regularnie
              czyścić podwozie i traktować je środkami antykorozyjnymi. W
              przypadkach znacznego uszkodzenia może być konieczne spawanie lub
              wymiana uszkodzonych części.
            </p>
            <p>
              Drugim powszechnym problemem są mechaniczne uszkodzenia podwozia,
              które mogą wystąpić w wyniku kolizji, najechania na duże dziury
              lub inne przeszkody. Aby zdiagnozować takie uszkodzenia, należy
              zwrócić uwagę na deformacje lub pęknięcia. Można je wykryć za
              pomocą wizualnej inspekcji lub specjalistycznych narzędzi, takich
              jak detektory ultradźwiękowe. Naprawa mechanicznych uszkodzeń
              zazwyczaj obejmuje spawanie, prostowanie lub w skrajnych
              przypadkach wymianę uszkodzonych elementów.
            </p>
            <img
              className="quilon2"
              src={quilon2}
              title="quilon"
              alt="quilon"
            />
            <p>
              Problemy z zawieszeniem są również często związane z podwoziem.
              Zużyte amortyzatory, sprężyny lub wahacze mogą prowadzić do
              niestabilności pojazdu i pogorszenia sterowności. Aby zdiagnozować
              te problemy, należy zwrócić uwagę na nierównomierne zużycie opon,
              obce dźwięki podczas jazdy oraz ogólne odczucie sterowności
              pojazdu. Naprawa zawieszenia może obejmować wymianę amortyzatorów,
              sprężyn, wahaczy lub innych komponentów.
            </p>
            <p>
              Kolejnym ważnym problemem mogą być defekty w mocowaniach podwozia.
              Nieprawidłowe lub słabe mocowanie może powodować skrzypienia,
              wibracje, a nawet utratę kontroli nad pojazdem. Diagnostyka takich
              problemów polega na sprawdzeniu wszystkich śrub i nakrętek pod
              kątem ich niezawodności i dokręcenia. Naprawa zazwyczaj polega na
              wymianie lub ponownym dokręceniu mocowań.
            </p>
            <p>
              Aby uniknąć większości problemów z podwoziem, ważne jest
              przestrzeganie regularnej obsługi technicznej. Obejmuje to
              regularne przeglądy, czyszczenie i traktowanie środkami
              antykorozyjnymi oraz terminową wymianę zużytych lub uszkodzonych
              komponentów. Wczesna diagnostyka i profilaktyka pozwalają uniknąć
              znacznych kosztów napraw w przyszłości.
            </p>
            <p>
              Ostatnim aspektem, który warto rozważyć, jest wpływ obciążenia na
              podwozie. Przeciążenie pojazdu może prowadzić do przyspieszonego
              zużycia, a nawet uszkodzenia podwozia. Przestrzeganie zaleceń
              producenta dotyczących maksymalnego dopuszczalnego obciążenia i
              rozkładu wagi pozwoli utrzymać podwozie w dobrym stanie przez
              długi czas.
            </p>
            <p>
              Przestrzegając powyższych porad i regularnie obsługując podwozie,
              można zapewnić niezawodną pracę samochodu i zwiększyć jego
              bezpieczeństwo na drodze. Nie zapominaj o regularnych przeglądach
              i terminowej naprawie, ponieważ to klucz do długotrwałej i
              bezpiecznej eksploatacji pojazdu.
            </p>
          </div>
          <Cataloge />
        </div>
      </div>
    </>
  );
}

function Article3() {
  return (
    <div className="body_wrapper">
      <Header />

      {/* <ButtomsLang /> */}
      <WhatsApp />
      <Quilon />
      <Footer />
    </div>
  );
}
export default Article3;
