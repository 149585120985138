import './Diagnostika.css';
import Header from '../../1_Header/Container/Header';
import changeoil from '../../../img/diagnostika/diagnostika.jpg';
import { FormaZayavkaContact } from '../../Forma/Forma_zayavka';
import Footer from '../../3_Footer/Footer';
import { ReactComponent as Foto1 } from '../../../img/diagnostika/foto1.svg';
import { ReactComponent as Foto2 } from '../../../img/diagnostika/foto2.svg';
import { ReactComponent as Foto3 } from '../../../img/diagnostika/foto3.svg';
import WhatsApp from '../../../WhatsUp/WhatsappLink';

function Diagnostika() {
  const scrolldown = () => {
    const scrollY = document.querySelector('body').clientHeight;

    window.scroll({ top: scrollY - 1000, behavior: 'smooth' });
  };
  return (
    <>
      <div className="body_wrapper">
        <Header />
        <div className="changeoil">
          <h1>Konserwacja i diagnostyka samochodów</h1>
          <div className="changeoil__img-text">
            <img src={changeoil} alt="changeoil" className="changeoil-img" />
            <div className="changeoil-text">
              <p>
                Jak wiadomo, przeglądy okresowe, wymiana i naprawa punktowa
                poszczególnych części Twojego samochodu nie tylko nie wymagają
                znacznych kosztów, ale także gwarantują bezpieczeństwo na
                drodze, komfort obsługi i ogólnie dłuższą żywotność samochodu.
              </p>
              <p>
                Regularna konserwacja jest ważna, aby utrzymać pojazd w
                doskonałym stanie. Oferujemy pełen zakres usług, w tym wymianę
                oleju, kontrolę układu hamulcowego, kontrolę poziomu płynu
                chłodzącego i wiele innych.
              </p>
              <p>
                Nasza zaawansowana technologia diagnostyczna pozwala szybko i
                dokładnie identyfikować problemy. Niezależnie od tego, czy jest
                to problem z silnikiem, układem elektrycznym czy skrzynią
                biegów, zapewnimy dokładną analizę i skuteczne rozwiązanie.
              </p>
              <div className="changeoil-zayavka">
                <button
                  onClick={scrolldown}
                  type="button"
                  className="changeoil-link"
                >
                  Zarejestruj się w serwisie
                </button>
              </div>
            </div>
          </div>
          <h2 className="changeoil-h2">Konserwacja samochodów obejmuje:</h2>
          <div className="changeoil__services">
            <div className="changeoil__item">
              <div className="changeoil__item-title">
                <Foto1 /> <h3>wymiana filtrów</h3>
              </div>
              <ul className="changefiltr">
                <li>oleju </li>
                <li>salon</li>
                <li>powietrze</li>
                <li>paliwo</li>
              </ul>
            </div>
            <div className="changeoil__item">
              <div className="changeoil__item-title">
                <Foto2 /> <h3> wymiana płynu</h3>
              </div>
              <ul className="changefiltr">
                <li>olej </li>
                <li>płyn hamulcowy</li>
                <li>hydrauliczne wspomaganie kierownicy itp</li>
              </ul>
            </div>
            <div className="changeoil__item">
              <div className="changeoil__item-title">
                <Foto3 /> <h3>wymiana paska</h3>
              </div>
              <ul className="changefiltr">
                <li>pasek generatora </li>
                <li>pasek rozrządu (rzadko łańcuch rozrządu)</li>
                <li>świece</li>
                <li>klocki i tarcze hamulcowe</li>
              </ul>
            </div>
          </div>
          <div className="formazayavka">
            <FormaZayavkaContact />
          </div>
        </div>
        <Footer />
        <WhatsApp />
      </div>
    </>
  );
}

export default Diagnostika;
