import React, { useState } from 'react';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { Button, ButtonLang } from '../../../UI/Buttons/Buttons';
import Lang from '../../../UI/Lang/Lang';
import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import foto from '../../../img/about/about.jpg';
import './About.css';
import WhatsApp from '../../../WhatsUp/WhatsappLink';
import { ReactComponent as Foto1 } from '../../../img/about/about.svg';
import { ReactComponent as Foto2 } from '../../../img/about/about2.svg';
import { ReactComponent as Foto3 } from '../../../img/about/about3.svg';
import { ReactComponent as Foto4 } from '../../../img/about/about4.svg';
import { ReactComponent as Foto5 } from '../../../img/about/about5.svg';
import { ReactComponent as Foto6 } from '../../../img/about/about6.svg';
import { FormaZayavkaContact } from '../../Forma/Forma_zayavka';

function ButtomsLang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };
  const closeLang = () => {
    setLang(false);
  };
  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div>
        <Lang lang="lang" marginTop="20px">
          <Button onClick={changeLang} select="select">
            <span>PL</span>
            <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />
          </Button>
          <ButtonLang closeLang={closeLang} isLang={isLang}>
            {isLang ? (
              <Link to="/en/about">EN</Link>
            ) : (
              <Link to="/about">RU</Link>
            )}
          </ButtonLang>
        </Lang>
      </div>
    </>
  );
}

function AboutTable() {
  return (
    <div className="about">
      <div className="about-title">
        <h1>O nas</h1>
      </div>
      <div className="container about-container">
        <div className="about__content">
          <div className="about__content-text">
            <p>
              <strong>Rompar Serwis</strong> to wiodący serwis samochodowy,
              który oferuje pełen zakres usług związanych z obsługą i naprawą
              pojazdów. Nasi klienci mogą liczyć na profesjonalne podejście do
              wszelkich zadań, od rutynowej konserwacji technicznej po
              skomplikowane prace naprawcze.
            </p>

            <div className="about__content-img">
              <img src={foto} alt="О нас" />
            </div>
            <p>
              W Rompar Serwis kładziemy szczególny nacisk na przejrzystość i
              uczciwość w relacjach z klientami. Każdy etap pracy jest dokładnie
              omawiany, a koszty uzgadniane z wyprzedzeniem. Dostarczamy
              szczegółowy raport z wykonanych prac, co pozwala naszym klientom
              mieć pewność co do uczciwości cen i jakości świadczonych usług.
            </p>
            <p>
              Bezpieczeństwo Twojego pojazdu jest dla nas priorytetem. Używamy
              wyłącznie certyfikowanych części od renomowanych dostawców i
              przestrzegamy wszystkich standardów technicznych. Gwarantuje to
              niezawodność i trwałość napraw, a także bezpieczeństwo Twojego
              samochodu na drodze.
            </p>
            <p>
              Rompar Serwis zawsze skupia się na indywidualnych potrzebach
              każdego klienta. Uważnie słuchamy życzeń i proponujemy optymalne
              rozwiązania, które uwzględniają specyfikę Twojego pojazdu oraz
              Twoje osobiste preferencje. Nasi specjaliści są zawsze gotowi do
              udzielenia fachowej porady i pomocy w wyborze najlepszych opcji
              obsługi.
            </p>
            <p>
              Rompar Serwis dąży do zapewnienia maksymalnego zadowolenia
              klientów, oferując niezawodny i profesjonalny serwis, któremu
              można zaufać.
            </p>
          </div>
        </div>
      </div>
      <h2 className="zasady-h2">zasady firmy</h2>
      <div className="zasady__list">
        <div className="zasay__list-item">
          <div>
            <Foto1 />
          </div>
          <h3>Jakość obsługi</h3>
          <p>
            Gwarantujemy wysoką jakość obsługi Twojego samochodu, wykorzystując
            nowoczesny sprzęt i umiejętności naszych doświadczonych mechaników.
          </p>
        </div>
        <div className="zasay__list-item">
          <div>
            <Foto3 />
          </div>
          <h3>Przejrzystość i uczciwość</h3>
          <p>
            Wszystkie prace i koszty są uzgadniane z klientem z wyprzedzeniem.
            Zapewniamy przejrzystą komunikację i dostarczamy szczegółowy raport
            z wykonanych prac.
          </p>
        </div>
        <div className="zasay__list-item">
          <div>
            <Foto2 />
          </div>
          <h3>Bezpieczeństwo</h3>
          <p>
            Używamy wyłącznie certyfikowanych części i przestrzegamy wszystkich
            standardów technicznych, aby zapewnić bezpieczeństwo Twojego
            pojazdu.
          </p>
        </div>
        <div className="zasay__list-item">
          <div>
            <Foto4 />
          </div>
          <h3> Indywidualne podejście</h3>
          <p>
            Uwzględniamy potrzeby i życzenia każdego klienta, zapewniając
            spersonalizowaną obsługę i profesjonalne porady.
          </p>
        </div>
        <div className="zasay__list-item">
          <div>
            <Foto5 />
          </div>
          <h3> Konkurencyjne ceny</h3>
          <p>
            Oferujemy konkurencyjne ceny na wszystkie usługi, zachowując przy
            tym wysoką jakość obsługi.
          </p>
        </div>
        <div className="zasay__list-item">
          <div>
            <Foto6 />
          </div>
          <h3>Ciągłe doskonalenie</h3>
          <p>
            Stale doskonalimy nasze metody pracy i sprzęt, aby spełniać
            najwyższe standardy branżowe i zaspokajać oczekiwania naszych
            klientów.
          </p>
        </div>
      </div>
      <div className="formazayavka">
        <FormaZayavkaContact />
      </div>
    </div>
  );
}

function About() {
  return (
    <>
      <div className="body_wrapper">
        <Header />

        {/* <ButtomsLang /> */}
        <WhatsApp />
        <AboutTable />
        <Footer />
      </div>
    </>
  );
}
export default About;
