import SwiperArticles from '../../Swiper/Swiper';
import './Article.css';

function Articles() {
  return (
    <>
      <div className="article">
        <h2 className="article-title">
          <span>Wszystko o naprawie i diagnostyce samochodów...</span>
        </h2>
        <SwiperArticles />
      </div>
    </>
  );
}
export default Articles;
