import React, { useState } from 'react';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { Button, ButtonLang } from '../../../UI/Buttons/Buttons';
import Lang from '../../../UI/Lang/Lang';
import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';

import foto from '../../../img/contact/plan.jpg';
import './Contact.css';
import WhatsApp from '../../../WhatsUp/WhatsappLink';
import MyPhone from '../../../UI/MyPhone/MyPhone';
import MyEmail from '../../../UI/MyEmail/MyEmail';

import MySocialLinks from '../../../UI/MySocialLinks/MySocialLinks';

function ButtomsLang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };
  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div>
        <Lang lang="lang" marginTop="20px">
          <Button onClick={changeLang} select="select">
            <span>PL</span>
            <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />
          </Button>
          <ButtonLang isLang={isLang}>
            {isLang ? (
              <Link to="/en/contact">EN</Link>
            ) : (
              <Link to="/contact">RU</Link>
            )}
          </ButtonLang>
        </Lang>
      </div>
    </>
  );
}

function ContactTable() {
  return (
    <div className="contact">
      <div className="contacts-title">
        <h1>KONTAKT</h1>
      </div>
      <div className="container contacts__container">
        <div className="contacts__item">
          <div className="contacts__item-phone">
            <p>Numer telefonu:</p>
            <MyPhone style={{ color: 'rgb(21, 4, 62)', marginTop: '10px' }} />
          </div>
          <div className="contacts__item-phone">
            <p>Email:</p>
            <MyEmail
              icons=""
              style={{
                color: 'rgb(21, 4, 62)',
                marginTop: '10px',
                fontSize: '25px',
              }}
            >
              warsztat.rompar@op.pl
            </MyEmail>
          </div>

          <div className="contacts__item-social">
            <p>Jesteśmy w sieciach społecznościowych:</p>
            <div className="contacts__item-social-links">
              <MySocialLinks
                style={{ marginTop: '20px', marginLeft: '10px' }}
              />
            </div>
          </div>
          <div className="contacts__item-praca">
            <h2>Plan pracy</h2>
            <img src={foto} alt="foto" />
            <p>Pracujemy:</p>
            <p>Poniedziałek - piątek od 9:00 do 18:00</p>
            <p>Usługa weekendowa dostępna jest po wcześniejszym umówieniu.</p>
            <p>Działamy na terenie Gniezno i okolic</p>
          </div>
          <div className="adress">Gniezno, Wodna 4, Polska</div>
        </div>
      </div>
    </div>
  );
}
function Contact() {
  return (
    <div className="body_wrapper">
      <Header />

      {/* <ButtomsLang /> */}
      <ContactTable />
      <WhatsApp />
      <Footer />
    </div>
  );
}
export default Contact;
