import { MdOutlineEmail } from 'react-icons/md';
import { Link } from 'react-router-dom';
import './MyEmail.css';

function MyEmail({ children, ...props }) {
  const {
    className = 'email',
    classnamelink = 'email__link',
    icons = <MdOutlineEmail className="email_img" />,
  } = props;
  return (
    <Link
      title="email"
      className={className}
      to={'mailto:warsztat.rompar@op.pl'}
    >
      <p className={classnamelink}>
        {icons}
        <span {...props}>{children}</span>
      </p>
    </Link>
  );
}
export default MyEmail;
