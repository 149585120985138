import { Link } from 'react-router-dom';
import { CiFacebook } from 'react-icons/ci';
import { LuInstagram } from 'react-icons/lu';
import './MySocialLinks.css';

function MySocialLinks({ ...props }) {
  const { classnames = 'header__social-list' } = props;
  return (
    <div className={classnames}>
      <div {...props} className="header__social-link-item">
        <Link
          to="#"
          title="instagram"
          alt="instagram"
          target="_blank"
          className="header__instargam"
        >
          <LuInstagram className="instagram" />
        </Link>
      </div>
      <div {...props} className="header__social-link-item">
        <Link
          to="#"
          target="_blank"
          alt="facebook"
          title="facebook"
          className="header_facebook"
        >
          <CiFacebook className="facebook" />
        </Link>
      </div>
    </div>
  );
}

export default MySocialLinks;
