import './Naparawa.css';
import Header from '../../1_Header/Container/Header';
import changeoil from '../../../img/hodova/foto.jpg';
import { FormaZayavkaContact } from '../../Forma/Forma_zayavka';
import Footer from '../../3_Footer/Footer';
import WhatsApp from '../../../WhatsUp/WhatsappLink';

function NaparawaPodwozia() {
  const scrolldown = () => {
    const scrollY = document.querySelector('body').clientHeight;

    window.scroll({ top: scrollY - 1000, behavior: 'smooth' });
  };
  return (
    <>
      <div className="body_wrapper">
        <Header />
        <div className="changeoil">
          <h1>Naprawa podwozia samochodów</h1>
          <div className="changeoil__img-text">
            <img src={changeoil} alt="changeoil" className="changeoil-img" />
            <div className="changeoil-text">
              <p>
                Podwozie samochodu to zespół elementów i mechanizmów, który
                spełnia dwie główne funkcje: zapewnienie ruchu pojazdu;
                tłumienie wibracji, kołysania, drgań i innych podobnych
                czynników, co zwiększa zasoby innych części samochodu i zapewnia
                odpowiedni poziom komfortu kierowcy i pasażerom.
              </p>
              <p>
                Przeprowadzamy pełną analizę i diagnostykę podwozia, w tym
                sprawdzamy amortyzatory, sprężyny, łożyska, tuleje i inne
                kluczowe elementy. Nasz zespół jest w stanie zidentyfikować i
                usunąć wszelkie usterki, od prostych regulacji po pełne naprawy
                lub wymianę niezbędnych części.
              </p>
              <p>
                Przeprowadzamy szczegółową kontrolę wszystkich elementów
                podwozia, w tym amortyzatorów, sprężyn i rozpórek, aby
                zidentyfikować zużycie i potencjalne problemy.
              </p>
              <div className="changeoil-zayavka">
                <button
                  onClick={scrolldown}
                  type="button"
                  className="changeoil-link"
                >
                  Zarejestruj się w serwisie
                </button>
              </div>
            </div>
          </div>

          <div className="formazayavka">
            <FormaZayavkaContact />
          </div>
        </div>
        <Footer />
        <WhatsApp />
      </div>
    </>
  );
}

export default NaparawaPodwozia;
