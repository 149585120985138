import FormaZayavka from '../../Forma/Forma_zayavka';
import './Zayavka.css';

import { useState } from 'react';

function Zayavka() {
  const [isNotScroll, setNotScroll] = useState(false);

  const [isZayavka, setZayavka] = useState(true);
  if (isNotScroll) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'scroll';
  }

  const openZayavka = () => {
    setZayavka(!isZayavka);
  };
  const closeForm = () => {
    setZayavka(true);
  };
  return (
    <>
      <div
        className="zayavka"
        onClick={() => {
          openZayavka();
          setNotScroll(true);
        }}
      >
        Zamów usługę
      </div>
      <div
        onClick={() => {
          closeForm();
          setNotScroll(false);
        }}
        className={isZayavka ? 'hidden' : 'showForma'}
      >
        <FormaZayavka closeForm={closeForm} setNotScroll={setNotScroll} />
      </div>
    </>
  );
}
export default Zayavka;
